import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import LoginView from "@/views/LoginView.vue";
import DashboardView from "@/views/dashboard/DashboardView.vue";
import AdmView from "@/views/administracao/AdmView.vue";
import AdmListaUserView from "@/views/administracao/AdmListaUserView.vue";
import AdmCadUserView from "@/views/administracao/AdmCadUserView.vue";
import AdmEditUserView from "@/views/administracao/AdmEditUserView.vue";
import AdmListaEmpresaView from "@/views/empresa/AdmListaEmpresaView.vue";
import AdmCadEmpresaView from "@/views/empresa/AdmCadEmpresaView.vue";
import AdmEditEmpresaView from "@/views/empresa/AdmEditEmpresaView.vue";
import AdmListaFornecedorView from "@/views/fornecedor/AdmListaFornecedorView.vue";
import AdmCadFornecedorView from "@/views/fornecedor/AdmCadFornecedorView.vue";
import AdmEditFornecedorView from "@/views/fornecedor/AdmEditFornecedorView.vue";
import AdmListaProdutoView from "@/views/produto/AdmListaProdutoView.vue";
import AdmCadProdutoView from "@/views/produto/AdmCadProdutoView.vue";
import AdmCadcomboView from "@/views/produto/AdmCadcomboView.vue";
import AdmEditProdutoView from "@/views/produto/AdmEditProdutoView.vue";
import FinContasListaView from "@/views/financeiro/contas/FinContasListaView.vue";
import SolicitacaoListaView from "@/views/solicitacao/SolicitacaoListaView.vue";
import SolicitacaoSaidaCprodView from "@/views/solicitacao/SolicitacaoSaidaCprodView.vue";
import SolicitacaoSaidaEprodView from "@/views/solicitacao/SolicitacaoSaidaEprodView.vue";
import SolicitacaoEntradaCprodView from "@/views/solicitacao/SolicitacaoEntradaCprodView.vue";
import SolicitacaoEntradaEprodView from "@/views/solicitacao/SolicitacaoEntradaEprodView.vue";
import pdfcupomcompraView from "@/views/solicitacao/pdfcupomcompraView.vue";
import pdfcupomvendaView from "@/views/solicitacao/pdfcupomvendaView.vue";
import RelatorioView from "@/views/relatorio/RelatorioView.vue";
import PlanejamentoView from "@/views/planejamento/PlanejamentoView.vue";
import PlanejamentoCadView from "@/views/planejamento/PlanejamentoCadView.vue";
import PlanejamentoEditView from "@/views/planejamento/PlanejamentoEditView.vue";
import CourseView from "@/views/course/CourseView.vue";
import CourseCadView from "@/views/course/CourseCadView.vue";
import CourseEditView from "@/views/course/CourseEditView.vue";
import ChatView from "@/views/chat/ChatView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView,
  },
  {
    path: "/administracao",
    name: "administracao",
    component: AdmView,
  },
  {
    path: "/admlistauser",
    name: "admlistauser",
    component: AdmListaUserView,
  },
  {
    path: "/admcaduser",
    name: "admcaduser",
    component: AdmCadUserView,
  },
  {
    path: "/admedituser/:id",
    name: "admedituser",
    component: AdmEditUserView,
  },
  {
    path: "/admlistaempresa",
    name: "admlistaempresa",
    component: AdmListaEmpresaView,
  },
  {
    path: "/admcadempresa",
    name: "admcadempresa",
    component: AdmCadEmpresaView,
  },
  {
    path: "/admeditempresa/:id",
    name: "admeditempresa",
    component: AdmEditEmpresaView,
  },
  {
    path: "/admlistafornecedor",
    name: "admlistafornecedor",
    component: AdmListaFornecedorView,
  },
  {
    path: "/admcadfornecedor",
    name: "admcadfornecedor",
    component: AdmCadFornecedorView,
  },
  {
    path: "/admeditfornecedor/:id",
    name: "admeditfornecedor",
    component: AdmEditFornecedorView,
  },
  {
    path: "/admlistaproduto",
    name: "admlistaproduto",
    component: AdmListaProdutoView,
  },
  {
    path: "/admcadproduto",
    name: "admcadproduto",
    component: AdmCadProdutoView,
  },
  {
    path: "/admcadcomboproduto",
    name: "admcadcomboproduto",
    component: AdmCadcomboView,
  },
  {
    path: "/admeditproduto/:id",
    name: "admeditproduto",
    component: AdmEditProdutoView,
  },
  {
    path: "/solicitacao",
    name: "solicitacao",
    component: SolicitacaoListaView,
  },
  {
    path: "/solicitacaosaidaCproduto",
    name: "solicitacaosaidaCproduto",
    component: SolicitacaoSaidaCprodView,
  },
  {
    path: "/solicitacaosaidaEproduto/:id",
    name: "solicitacaosaidaEproduto/:id",
    component: SolicitacaoSaidaEprodView,
  },
  {
    path: "/solicitacaoEntradaCproduto",
    name: "solicitacaoEntradaCproduto",
    component: SolicitacaoEntradaCprodView,
  },
  {
    path: "/solicitacaoEntradaEproduto/:id",
    name: "solicitacaoEntradaEproduto/:id",
    component: SolicitacaoEntradaEprodView,
  },
  {
    path: "/relatorio",
    name: "relatorio",
    component: RelatorioView,
  },
  {
    path: "/admlistaPlanejamento",
    name: "admlistaPlanejamento",
    component: PlanejamentoView,
  },
  {
    path: "/admcadPlanejamento",
    name: "admcadPlanejamento",
    component: PlanejamentoCadView,
  },
  {
    path: "/admeditPlanejamento/:id",
    name: "admeditPlanejamento",
    component: PlanejamentoEditView,
  },
  {
    path: "/admlistacourse",
    name: "admlistacourse",
    component: CourseView,
  },
  {
    path: "/admcadcourse",
    name: "admcadcourse",
    component: CourseCadView,
  },
  {
    path: "/admeditcourse/:id",
    name: "admeditcourse",
    component: CourseEditView,
  },
  {
    path: "/admchat",
    name: "admchat",
    component: ChatView,
  },
  {
    path: "/fincontas",
    name: "fincontas",
    component: FinContasListaView,
  },
  {
    path: "/pdfcupomcompra/:id",
    name: "pdfcupomcompra/:id",
    component: pdfcupomcompraView,
  },
  {
    path: "/pdfcupomvenda/:id",
    name: "pdfcupomvenda/:id",
    component: pdfcupomvendaView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
