<template>
  <navbar-component></navbar-component>
  <!-- Header -->
  <div class="relative bg-blue-600 md:pt-32 pb-32 pt-12">
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div
        class="container mx-auto my-12 h-auto flex flex-wrap justify-between items-start text-sm p-8 pt-8 pb-4 box-border border-4 border-gray-400 bg-gray-100"
      >
        <a class="text-2xl text-center text-black font-bold"
          >Cupom de venda : {{ id_solicitacao }}
        </a>
        <RouterLinkButton
          btnText=""
          color="sky"
          tipo="fas"
          icone="arrow-left"
          url="/solicitacao"
        />
        <div class="bg-green-500 w-full h-1"></div>
        <a class="text-2xl text-left text-black font-bold"
          >Cliente : {{ id_cliente }}-{{ razaoSocial_cliente }}</a
        >
        <div class="bg-green-500 w-full h-1"></div>
        <a class="text-2xl text-left text-black font-bold">
          Data: {{ dt_vendacupom }}
        </a>
        <div class="bg-green-500 w-full h-1"></div>
        <a class="text-2xl text-left text-black font-bold">
          Valor total pedido : {{ vlrTotalPedido }}
        </a>
        <div class="bg-green-500 w-full h-1"></div>
        <a class="text-2xl text-left text-black font-bold">
          FORMA DE PAGAMENTO
        </a>
        <div class="bg-green-500 w-full h-1"></div>
        <table class="table-auto">
          <thead>
            <tr class="text-2xl text-left text-black font-bold">
              <th class="">Vlr vista {{ formatMoeda(vlrTotalavista) }}</th>
              <th class="">.Vlr prazo {{ formatMoeda(vlrTotalaprazo) }}</th>
              <th class="">.Vlr débito {{ formatMoeda(vlrTotaldebito) }}</th>
              <th class="">area de impressão teste 0.</th>
              <th class="">.</th>
            </tr>
          </thead>
        </table>
        <a class="text-2xl text-left text-black font-bold">
          <th class="">Vlr crédito {{ formatMoeda(vlrTotalcredito) }}</th>
        </a>
        <div class="bg-green-500 w-full h-1"></div>
        <div class="text-2xl text-left text-black font-bold">Itens</div>
        <div class="bg-green-500 w-full h-1"></div>
        <table class="table-auto text-xl">
          <thead>
            <tr class="text-black-600 bg-gray-300">
              <th class="border px-4 py-2">Descrição</th>
              <th class="border px-4 py-2">Qtde</th>
              <th class="border px-4 py-2">Vlr unitario</th>
              <br />
              <th class="border px-4 py-2">Vlr total</th>
              <th class="">area de impressão teste 1.......</th>
              <th class="">area de impressão teste 2.......</th>
            </tr>
          </thead>

          <tbody>
            <tr
              class="text-gray-500 bg-gray-100"
              v-for="listSolicitItem in listSolicitItems"
              :key="listSolicitItem"
            >
              <td>{{ listSolicitItem.nome_produto }}</td>
              <td>{{ listSolicitItem.quantidade }}</td>
              <td>{{ listSolicitItem.valor_unitario }}</td>
              <br />
              <td>{{ listSolicitItem.valor_totalProduto }}</td>
              <th class="">area de impressão teste 1........</th>
              <th class="">area de impressão teste 2........</th>
            </tr>
          </tbody>
        </table>

        <div class="bg-green-500 w-full h-1"></div>
        <div class="flex items-center justify-center p-2">
          <v-pagination
            v-model="pageItems"
            :pages="pageCountItems"
            :range-size="1"
            active-color="#337aff"
            @update:modelValue="getSolicitItemList"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ref } from "vue";
import NavbarComponent from "@/components/Navbar.vue";
import SidebarComponent from "@/components/Sidebar.vue";
import AdmMenuComponent from "@/components/parcial/adm/AdmMenu.vue";
import RouterLinkButton from "@/components/global/RouterLinkButton.vue";
import TextInput from "@/components/global/TextInput.vue";
import TextInputDate from "@/components/global/TextInputDate.vue";
import TextInputMoeda from "@/components/global/TextInputMoeda.vue";
import LoadingButton from "@/components/global/LoadingButton.vue";
import SubmitFormButton from "@/components/global/SubmitFormButton.vue";
import { useRoute, useRouter } from "vue-router";
import { useUserStore } from "@/store/user-store";
import { useSolicitationStore } from "@/store/solicitation-store";

export default {
  components: {
    NavbarComponent,
    SidebarComponent,
    AdmMenuComponent,
    RouterLinkButton,
    LoadingButton,
    TextInput,
    SubmitFormButton,
    TextInputDate,
    TextInputMoeda,
  },
  name: "regular-modal",
  data() {
    const userStore = useUserStore();
    const SolicitationStore = useSolicitationStore();
    const route = useRoute();
    const router = useRouter();

    const url = process.env.VUE_APP_API_URL;
    let hover = ref(false);
    let showModal = ref(false);
    let showModalSupplier = ref(false);
    let showModalProduct = ref(false);
    let showModalProductCB = ref(false);

    let searchCompany = ref(null);
    let listCompanies = ref(null);
    let pageCompanies = ref(1);
    let pageCountCompanies = ref(null);

    let errors = ref([]);

    return {
      date: new Date().getFullYear(),
      useradministrador: userStore.administrador,
      userid_company: userStore.id_empresa,
      id_responsavel: userStore.id,
      name_responsavel: userStore.full_name,
      SolicitationStore: SolicitationStore,
      route: route,
      router: router,
      url_api: url,
      hover: hover,
      showModal: showModal,
      showModalSupplier: showModalSupplier,
      showModalProduct: showModalProduct,
      showModalProductCB: showModalProductCB,
      searchCompany: searchCompany,
      listCompanies: listCompanies,
      pageCompanies: pageCompanies,
      pageCountCompanies: pageCountCompanies,
      listStocks: ref(null),
      searchStock: ref(null),
      searchStockCB: ref(null),
      pageStocks: ref(1),
      pageCountStocks: ref(null),
      searchSupplier: ref(null),
      listSuppliers: ref(null),
      pageSuppliers: ref(1),
      pageCountSuppliers: ref(null),
      listSolicitItems: ref(null),
      pageItems: ref(1),
      pageCountItems: ref(null),
      errors: errors,
      error: errors,
      id_empresa: ref(null),
      isLoading: ref(false),
      id_solicitacao: ref(null),
      razaoSocial: ref(null),
      id_empresa: ref(null),
      id_cliente: ref(null),
      dt_vendacupom: ref(null),
      dt_retirada: ref(null),
      dt_entrega: ref(null),
      vlrtotal_item: ref(null),
      quantidade: ref(null),
      vlrunitario: ref(null),
      modelo: ref("pedidovenda"),
      situacao: ref(null),
      vlrTotalPedido: ref(0),
      vlrTotalavista: ref(0),
      vlrTotalaprazo: ref(0),
      vlrTotaldebito: ref(0),
      vlrTotalcredito: ref(0),
    };
  },
  methods: {
    async getSolicitationsale() {
      // Make a GET request to the API
      const response = await this.SolicitationStore.fetchByIdSolicitation(
        this.route.params.id
      );
      this.id_solicitacao = this.SolicitationStore.id_solicitacao || null;
      this.id_cliente = this.SolicitationStore.id_cliente || null;
      this.razaoSocial_cliente =
        this.SolicitationStore.razaoSocial_cliente || null;
      this.dt_vendacupom = this.SolicitationStore.dt_emissao || null;
      this.situacao = this.SolicitationStore.situacao || null;
      this.vlrTotalPedido = this.SolicitationStore.vlrTotalPedido || null;
      this.vlrTotalavista = this.SolicitationStore.vlrTotalavista || null;
      this.vlrTotalaprazo = this.SolicitationStore.vlrTotalaprazo || null;
      this.vlrTotaldebito = this.SolicitationStore.vlrTotaldebito || null;
      this.vlrTotalcredito = this.SolicitationStore.vlrTotalcredito || null;
      this.$snotify.info("Aguarde atualizando ... !");
    },
    getSolicitItemList() {
      axios
        .get(
          this.url_api +
            "/api/salesoitemlist/" +
            this.route.params.id +
            "?page=" +
            this.pageItems
        )

        // console.log(resItem)

        .then((response) => {
          // Handle the successful response
          // console.log(response.data);
          this.pageCountItems = response.data.page_countItem;
          this.listSolicitItems = response.data.paginateItem.data;
          this.vlrtotal = response.data.valorTotalPedido;
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    formatMoeda(moedaString) {
      const atual = moedaString;
      //     const atual = parseFloat(moedaString.replace(",", ".")).toFixed(2);
      //   const valor = parseFloat(moedaString.replace(/[R\$\s]/g, ""));
      const valorFormatado = Intl.NumberFormat("pt-br", {
        style: "currency",
        currency: "BRL",
      }).format(atual);

      return valorFormatado;
    },
    onPageChange() {
      this.getSolicitationsale();
      this.getSolicitItemList();
    },
  },
  mounted() {
    this.getSolicitationsale();
    this.getSolicitItemList();
    window.addEventListener("load", window.print());
  },
};
</script>
